import { type KyResponse, type HTTPError, type Options, type KyInstance } from 'ky';

import { type Nullable } from 'helpers/interface';

export type ApiClientResult = string | Blob | ArrayBuffer | FormData;

export type ApiClientResponseType = 'json' | 'text' | 'blob' | 'arrayBuffer' | 'formData';

export const enum HTTPStatus {
  Empty = 0,
  OK = 200,
  Created = 201,
  NoContent = 204,
  MultipleChoices = 300,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  UnprocessableEntity = 422,
  InternalServerError = 500,
}

export const enum AuthType {
  Bearer = 'Bearer',
  Basic = 'Basic',
  OAuth = 'OAuth',
}

export interface RequestErrorParams {
  endpoint: string;
  method: string;
  logTimestamp: number;
  requestTimestamp: number;
  parsedError: unknown;
  requestBody?: unknown;
}

export type ApiClientOptions = {
  baseURL: string;
  getCredentials?: () => Nullable<string>;
  getRegion?: () => Nullable<string>;
  onError?: (error: unknown, params: RequestErrorParams) => void;
  authType?: AuthType;
  prefix?: string;
  apiVersion?: string;
};

export type ApiClientRequestOptions = Options & {
  responseType?: ApiClientResponseType;
  onProgress?: (event: ProgressEvent) => void;
  onError?: (error: unknown, params: RequestErrorParams) => void;
  dontRetry?: boolean;
};

export type ApiClientBatchRequest<T> = { requests: T[] };

export type ApiClientErrorType = unknown;

export type ApiClientError<E extends ApiClientErrorType> = {
  http: E | null;
  status: HTTPStatus;
  local: Error | null;
};

export type KyHTTPError = HTTPError & {
  response: KyResponse;
};

type SuccessResponse<T> = {
  response: KyResponse;
  result: T;
  error: null;
};

type ErrorResponse<E extends ApiClientErrorType> = {
  response: KyResponse;
  result: null;
  error: ApiClientError<E>;
};

export type ApiClientResponse<T, E extends ApiClientErrorType> = SuccessResponse<T> | ErrorResponse<E>;

export type ApiClientPromise<T, E extends ApiClientErrorType> = Promise<ApiClientResponse<T, E>>;

export type ApiClientInstance = {
  instance: KyInstance;
  baseURL: string;
  get: <T, E extends ApiClientErrorType>(url: string, options?: ApiClientRequestOptions) => ApiClientPromise<T, E>;
  post: <T, E extends ApiClientErrorType>(url: string, options?: ApiClientRequestOptions) => ApiClientPromise<T, E>;
  put: <T, E extends ApiClientErrorType>(url: string, options?: ApiClientRequestOptions) => ApiClientPromise<T, E>;
  patch: <T, E extends ApiClientErrorType>(url: string, options?: ApiClientRequestOptions) => ApiClientPromise<T, E>;
  delete: <T, E extends ApiClientErrorType>(url: string, options?: ApiClientRequestOptions) => ApiClientPromise<T, E>;
};
