const enum ServiceURLs {
  AGENT_API = 'https://api.livechatinc.com/agent/status',
  CONFIGURATION_API = 'https://api.livechatinc.com/configuration/status',
  REST_API = 'https://api.livechatinc.com/service/alive',
}

import connectivityStatus from './service';
import { type ConnectivityStatusInstance } from './types';

export const connectivityStatusClient: ConnectivityStatusInstance = connectivityStatus.create({
  servicesURLs: [ServiceURLs.AGENT_API, ServiceURLs.CONFIGURATION_API, ServiceURLs.REST_API],
});
