// @ts-strict-ignore
import { getHashParam } from 'helpers/url';
import { loadCredentialsFromCookies, storeCredentials } from 'services/auth/auth-storage-manager';

let isGhost = false;

function credentialsExistInHash(): boolean {
  return !!getHashParam('access_token');
}

export function saveCredentials(): void {
  if (credentialsExistInHash()) {
    // We are freshly logged in by accounts.
    // Save the credentials for the next couple of days, so we're not logged out after refresh.

    const token = getHashParam('access_token');
    const scopesString = getHashParam('scope');
    const expiresIn = getHashParam('expires_in');
    isGhost = getHashParam('frenzied') === '1';

    const shouldSaveCookies = !isGhost;

    if (token && scopesString) {
      storeCredentials({ token, scopesString, shouldSaveCookies, expiresIn });
    }
  }

  if (!isGhost) {
    loadCredentialsFromCookies();
  }
}

export function isGhostLogin(): boolean {
  return isGhost;
}
