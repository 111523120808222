import { type SignoutReason } from 'interfaces/session';

import { getConfig } from './config';
import { type KeyMap } from './interface';
import { JSONParse } from './json';
import { redirectTo } from './redirect-to';
import { decodeQueryString, updateQueryString } from './url';

function getAccountsClientId(): string {
  const config = getConfig();

  return USES_GCS_BUCKET ? config.gcsAccountsClientId : config.accountsClientId;
}

function getAccountsRedirectUri(): string {
  return window.location.origin;
}

type AccountsUrlParams = {
  redirectUri: string;
  clientId: string;
  responseType: 'token' | 'code';
};

function getAccountsUrlParams(): AccountsUrlParams {
  const redirectUri = getAccountsRedirectUri();
  const clientId = getAccountsClientId();
  const responseType = 'token';

  return { redirectUri, clientId, responseType };
}

export function buildAccountsUrl(accountsParams: Partial<AccountsUrlParams & { path: string }>): string {
  const defaultParams = getAccountsUrlParams();

  const params = {
    path: '',
    ...defaultParams,
    ...accountsParams,
  };

  const { path, redirectUri, responseType, clientId } = params;

  return `${getConfig().accounts}/${path}?redirect_uri=${encodeURIComponent(
    redirectUri
  )}&response_type=${responseType}&client_id=${clientId}`;
}

/**
 * This function serializes an object into a query string.
 * It iterates over the properties of the object and encodes them using encodeURIComponent.
 * It then joins the encoded properties with '&' and replaces any '%20' with '+'.
 *
 * @param obj - The object to serialize.
 * @returns The serialized query string.
 */
export function serializeQueryStringObject(obj: Record<string, string | number | boolean>): string {
  const str: string[] = [];
  for (const p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  }

  return str.join('&').replace(/%20/g, '+');
}

/**
 * This function generates a redirection path for accounts.
 * It first decodes the original and accounts query strings.
 * It then creates a new query string object by combining the original, accounts, and redirection query strings.
 * If the combined query string object has any properties, it serializes it into a query string.
 * It then returns the serialized query string.
 *
 * @returns The query string for accounts path.
 */
export function getAccountsRedirectionQueryString(): string {
  const originalQuery = decodeQueryString(location.search.substring(1));
  const params = getAccountsUrlParams();
  const accountsQuery = {
    /* eslint-disable @typescript-eslint/naming-convention */
    redirect_uri: params.redirectUri,
    response_type: params.responseType,
    client_id: params.clientId,
    /* eslint-enable @typescript-eslint/naming-convention */
  };
  const redirectionQuery = {};
  const state = getAccountsRedirectionStateParam();

  if (state) {
    redirectionQuery['state'] = state;
  }

  // Combine query string objects using the spread operator
  const queryStringObject = {
    ...originalQuery,
    ...accountsQuery,
    ...redirectionQuery,
  };

  let queryString = '';
  if (Object.keys(queryStringObject).length) {
    queryString = '?' + serializeQueryStringObject(queryStringObject);
  }

  return queryString;
}

function getAccountsRedirectionStateParam(): string | null {
  const redirectToUrl = getFallbackRedirectUrl();

  if (redirectToUrl) {
    return buildAccountsStateParam({
      redirectTo: redirectToUrl,
    });
  }

  return null;
}

function getFallbackRedirectUrl(): string | null {
  const { pathname, search, hash } = window.location;
  const redirectUrl = pathname + search + (hash.includes('access_token') ? '' : hash);
  const exceptionPages = ['startup-error', 'signout', 'error.html', 'keep-on-chatting-with-customers'];

  if (!USES_GCS_BUCKET) {
    const exceptionPage = exceptionPages.find((page) => redirectUrl.includes(page));

    if (exceptionPage) {
      return redirectUrl.substring(0, redirectUrl.indexOf(`/${exceptionPage}`)) || null;
    }

    if (redirectUrl === '/') {
      return null;
    }

    return redirectUrl;
  }

  if (redirectUrl === '/' || exceptionPages.some((page) => redirectUrl.includes(page))) {
    // don't redirect to specific pages after re-login
    return null;
  }

  return redirectUrl;
}

function buildAccountsStateParam(queryObject: KeyMap): string {
  const resultQueryObject = {};
  Object.keys(queryObject).map((key) => {
    resultQueryObject[key] = encodeURIComponent(queryObject[key]);
  });

  return JSON.stringify(resultQueryObject);
}

export function redirectToAccounts(): void {
  const queryString = getAccountsRedirectionQueryString();
  redirectTo(`${getConfig().accounts}/${queryString}`);
}

export function redirectToAccountsSignout(reason?: SignoutReason): void {
  let url = buildAccountsUrl({ path: 'signout' });

  if (reason) {
    url = updateQueryString(url, 'identity_exception', 'identity_lost');
    url = updateQueryString(url, 'state', reason);
  }

  redirectTo(url);
}

export function extractRedirectTo(state: string): string | null {
  if (state != null) {
    const parsedState = JSONParse<{ redirectTo: string }>(state);
    if (parsedState !== null && parsedState.redirectTo !== null) {
      const path = decodeURIComponent(parsedState.redirectTo);
      const redirectPath = path[0] === '/' ? path : `/${path}`;

      return redirectPath;
    }
  }

  return null;
}
