export enum DebugLogsNamespace {
  AppApiAccess = 'app:api-access',
  AppApiClient = 'app:api-client',
  AppApplications = 'app:applications',
  AppBusinessEvents = 'app:businessEvents',
  AppConnectivityStatus = 'app:connectivity-status',
  AppDebug = 'app:debug',
  AppErrors = 'app:errors',
  AppLc3JsonProtocol = 'app:lc3:jsonProtocol',
  AppLc3PlatformProtocolParser = 'app:lc3:platformProtocolParser',
  AppPerformance = 'app:performance',
  AppServerConnection = 'app:server-connection',
  AppServerConnectionErrors = 'app:server-connection-errors',
  AppWebSocket = 'app:web-socket',
  FeaturesSoundNotifications = 'features:sound-notifications',
  IntegrationLoadTracker = 'integration:loadTracker',
}
