import { type AgentPriority } from 'constants/agent-priority';
import { type Day } from 'constants/day';
import { type AcceptingChatsStatus } from 'constants/login-status';
import { type Permission } from 'constants/permission';
import { type BooleanNumericFlag } from 'helpers/boolean';
import { type ApiClientBatchRequest } from 'services/connectivity/http/types';

export interface AgentBase {
  groups?: AgentGroup[];
  work_scheduler?: WorkScheduler;
  job_title?: string;
  max_chats_count?: number;
  last_logout?: string;
  id: string;
  name: string;
  avatar?: string;
}

export interface Agent extends AgentBase {
  role: Permission;
  login_status?: AcceptingChatsStatus;
  mobile?: string;
  awaiting_approval?: boolean;
  notifications?: Notification[];
  email_subscriptions?: EmailSubscription[];
  suspended?: boolean;
  'email.bounce'?: BooleanNumericFlag;
  'email.bounce.type'?: BooleanNumericFlag;
  'email.bounce.date'?: string;
  visited_welcome_tutorial?: boolean;
  account_id?: string;
}

export interface AgentGroup {
  id: number;
  priority: AgentPriority;
}

export enum Notification {
  NewVisitor = 'new_visitor',
  IncomingChat = 'incoming_chat',
  ReturningVisitor = 'returning_visitor',
  QueuedVisitor = 'queued_visitor',
  VisitorIsTyping = 'visitor_is_typing',
  NewGoal = 'new_goal',
  UnassignedChats = 'unassigned_chats',
  Ticket = 'ticket',
  InactiveNotifications = 'inactive_notifications',
  IncomingMessageForFocusedChat = 'incoming_message_for_focused_chat',
  RepeatSoundNotifications = 'repeat_sound_notifications',
  MuteAllSounds = 'mute_all_sounds',
  TicketNotifications = 'ticket_notifications',
}

export enum EmailSubscription {
  DailySummary = 'daily_summary',
  WeeklySummary = 'weekly_summary',
  UnassignedChatNotifications = 'unassigned_chat_notifications',
}

export interface WorkScheduler {
  timezone: string;
  schedule: WorkSchedule[];
}

export interface WorkSchedule {
  day: Day;
  enabled: boolean;
  start: string;
  end: string;
}

export enum AgentField {
  Groups = 'groups',
  WorkScheduler = 'work_scheduler',
  EmailSubscriptions = 'email_subscriptions',
  Notifications = 'notifications',
  JobTitle = 'job_title',
  Mobile = 'mobile',
  MaxChatsCount = 'max_chats_count',
  Suspended = 'suspended',
  AwaitingApproval = 'awaiting_approval',
  EmailBounce = 'email.bounce',
  EmailBounceType = 'email.bounce.type',
  EmailBounceDate = 'email.bounce.date',
  LoginStatus = 'login_status',
  LastLogout = 'last_logout',
  VisitedWelcomeTutorial = 'visited_welcome_tutorial',
}

export interface CreateAgentRequest extends Agent {}

export type BatchCreateAgentsRequest = ApiClientBatchRequest<CreateAgentRequest>;

export interface UpdateAgentRequest extends Partial<Agent> {
  id: string;
}

export type BatchUpdateAgentsRequest = ApiClientBatchRequest<UpdateAgentRequest>;

export type BatchSuspendAgentsRequest = ApiClientBatchRequest<SuspendAgentRequest>;

export type BatchApproveAgentsRequest = ApiClientBatchRequest<ApproveAgentRequest>;

export interface GetAgentRequest {
  id: string;
  fields?: AgentField[];
}

export interface ListAgentsRequest {
  filters?: {
    group_ids?: number[];
    suspended?: boolean;
  };
  fields?: AgentField[];
}

export interface DeleteAgentRequest {
  id: string;
}

export interface SuspendAgentRequest {
  id: string;
}

export interface UnsuspendAgentRequest {
  id: string;
}

export interface ApproveAgentRequest {
  id: string;
}

export interface ReactivateEmailRequest {
  agent_id: string;
}

export interface CreateAgentResponse {
  id: string;
}

export interface UpdateAgentResponse {
  id: string;
}

export interface GetAgentResponse extends Agent {}

export type ListAgentsResponse = Agent[];
