import { isValidRegion, updateRegion, createConfig } from '@config';
import type { IConfig } from '@config/interfaces';
import envConfig from 'env-config';

import { getCookie, removeCookie, setCookie } from './cookies';
import { getUrlParam } from './url';

export function updateDatacenter(config: IConfig, datacenter?: string): void {
  const region = datacenter || config.datacenter.current;

  if (isValidRegion(region)) {
    updateRegion(config, region);
    setCookie('region', region, {
      days: 365,
    });
  } else {
    removeCookie('region');
  }
}

let config: IConfig | null = null;

export function getConfig(): IConfig {
  if (!config) {
    config = createConfig(envConfig);
    const region = getUrlParam('region') || getCookie<string>('region');
    if (region) {
      updateDatacenter(config, region);
    }
  }

  return config;
}
