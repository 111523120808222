export enum Cookie {
  AccessToken = 'credentials.access_token',
  AutoTagsDisabled = 'no_auto_tags_model',
  ChatsEmptyStateBanner = 'chats-empty-state-banner',
  LastClosedBannerCookie = 'last_closed_banner',
  CompanyDetailsShown = 'company-details-shown',
  CouponCode = 'lc_coupon_code',
  EnableNotificationsBarSkipped = 'lc_enable-notifications-bar-skipped',
  NoChatsEmptyStateBanner = 'no-chats-empty-state-banner',
  Scopes = 'credentials.scopes',
  UnblockNotificationsBarSkipped = 'lc_unblock-notifications-bar-skipped',
  UtmSource = 'utm_source',
  WebsocketsOff = 'websockets_off',
}
