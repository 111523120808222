import { saveLicenseIdForRequestLogger } from 'services/analytics/log-request-error';
import { normalizeError as normalizeChatApiError } from 'services/connectivity/agent-chat-api/helpers';
import { licenseInfoClient } from 'services/connectivity/agent-chat-api/license-info/client';
import { type GetLicenseInfoResponse } from 'services/connectivity/agent-chat-api/license-info/types';
import { routingStatusClient } from 'services/connectivity/agent-chat-api/status/client';
import { type ListRoutingStatusesResponse } from 'services/connectivity/agent-chat-api/status/types';
import { AgentChatApiErrorType } from 'services/connectivity/agent-chat-api/types';
import { agentsClient } from 'services/connectivity/configuration-api/agents/client';
import { getAllAgentFields } from 'services/connectivity/configuration-api/agents/fields';
import { type ListAgentsResponse } from 'services/connectivity/configuration-api/agents/types';
import { botsClient } from 'services/connectivity/configuration-api/bots/client';
import { getAllBotFields } from 'services/connectivity/configuration-api/bots/fields';
import { type ListBotsResponse } from 'services/connectivity/configuration-api/bots/types';
import { groupsClient } from 'services/connectivity/configuration-api/groups/client';
import { getGroupFields } from 'services/connectivity/configuration-api/groups/fields';
import { type ListGroupsResponse } from 'services/connectivity/configuration-api/groups/types';
import { normalizeError as normalizeConfigurationApiError } from 'services/connectivity/configuration-api/helpers';
import { propertiesClient } from 'services/connectivity/configuration-api/properties/client';
import { PlatformNamespace } from 'services/connectivity/configuration-api/properties/constants';
import { type ListLicensePropertiesResponse } from 'services/connectivity/configuration-api/properties/types';
import { accountsClient } from 'services/connectivity/global-accounts-api/accounts/client';
import { ME_ACCOUNT_ID } from 'services/connectivity/global-accounts-api/accounts/constants';
import { type GetAccountResponse } from 'services/connectivity/global-accounts-api/accounts/types';
import { normalizeError as normalizeGlobalAccountsApiError } from 'services/connectivity/global-accounts-api/helpers';
import { tokensClient } from 'services/connectivity/global-accounts-api/tokens/client';
import { type TokenInfoResponse } from 'services/connectivity/global-accounts-api/tokens/types';

import { type StartupData, type StartupDataResult } from '../../interfaces/startup-data';

async function fetchAgents(): Promise<ListAgentsResponse> {
  const { result, error } = await agentsClient.list({ fields: getAllAgentFields() });

  if (error) {
    throw normalizeConfigurationApiError(error);
  }

  return result;
}

async function fetchBots(): Promise<ListBotsResponse> {
  const { result, error } = await botsClient.list({ all: true, fields: getAllBotFields() });

  if (error) {
    throw normalizeConfigurationApiError(error);
  }

  return result;
}

async function fetchRoutingStatuses(isGhost: boolean): Promise<ListRoutingStatusesResponse> {
  const { result, error } = await routingStatusClient.list({});

  if (error) {
    const normalizedError = normalizeChatApiError(error);

    if (isGhost && normalizedError.type == AgentChatApiErrorType.LICENSE_EXPIRED) {
      // ghost login should ignore license-expired error and allow app to load normally
      return [];
    }

    throw normalizedError;
  }

  return result;
}

async function fetchTokenInfo(): Promise<TokenInfoResponse> {
  const { result, error } = await tokensClient.info();

  if (error) {
    throw normalizeGlobalAccountsApiError(error);
  }

  return result;
}

async function fetchLicenseProperties(namespace?: PlatformNamespace): Promise<ListLicensePropertiesResponse> {
  const { result, error } = await propertiesClient.listLicense(namespace ? { namespace } : {});

  if (error) {
    throw normalizeConfigurationApiError(error);
  }

  return result;
}

async function fetchGroups(isGhost: boolean): Promise<ListGroupsResponse> {
  const { result, error } = await groupsClient.list({ fields: getGroupFields(!isGhost) });

  if (error) {
    throw normalizeConfigurationApiError(error);
  }

  return result;
}

async function fetchLicenseInfo(): Promise<GetLicenseInfoResponse> {
  const { result, error } = await licenseInfoClient.getLicenseInfo();

  if (error) {
    throw normalizeChatApiError(error);
  }

  // must save it asap, so that when error occurs, we can log it with proper license id
  saveLicenseIdForRequestLogger(result.license_id);

  return result;
}

async function fetchAccount(): Promise<GetAccountResponse> {
  const { result, error } = await accountsClient.get(ME_ACCOUNT_ID);

  if (error) {
    throw normalizeGlobalAccountsApiError(error);
  }

  return result;
}

export async function fetchStartupData(isGhost: boolean): Promise<StartupDataResult> {
  const [criticalData, additionalData] = await Promise.allSettled([
    Promise.all([
      fetchLicenseProperties(),
      fetchLicenseProperties(PlatformNamespace.BILLING),
      fetchLicenseProperties(PlatformNamespace.STATISTICS),
      fetchLicenseInfo(),
      fetchTokenInfo(),
      fetchAccount(),
      fetchAgents(),
      fetchBots(),
    ]),
    // these fetches may throw license-expired error but we still need the data from previous fetches to show error page or subscription section
    Promise.all([fetchRoutingStatuses(isGhost), fetchGroups(isGhost)]),
  ]);

  if (criticalData.status === 'rejected') {
    const failReason = criticalData.reason;

    return {
      success: false,
      failReason,
    };
  }

  const [
    coreLicenseProperties,
    billingLicenseProperties,
    statisticsLicenseProperties,
    licenseInfo,
    tokenInfo,
    account,
    agents,
    bots,
  ] = criticalData.value;

  const licenseProperties = {
    ...coreLicenseProperties,
    ...billingLicenseProperties,
    ...statisticsLicenseProperties,
  };

  const startupData: StartupData = {
    licenseProperties,
    licenseInfo,
    tokenInfo,
    account,
    agents,
    bots,
  };

  if (additionalData.status === 'rejected') {
    const failReason = additionalData.reason;

    return {
      startupData,
      success: false,
      failReason,
    };
  }

  const [routingStatuses, groups] = additionalData.value;

  return {
    startupData: {
      ...startupData,
      routingStatuses,
      groups,
    },
    success: true,
  };
}
