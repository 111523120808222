// @ts-strict-ignore
import { setHeaders } from './helpers';
import { isXMLHttpRequestBodyInit } from './type-guards';
import {
  type ApiClientResponse,
  type ApiClientOptions,
  type ApiClientRequestOptions,
  HTTPStatus,
  ApiClientErrorType,
} from './types';

/**
/**
 * Handles the successful XMLHttpRequest response.
 * @param xhr The XMLHttpRequest instance.
 */
function handleResponse<T>(xhr: XMLHttpRequest): ApiClientResponse<T, null> {
  let result: T;
  try {
    result = JSON.parse(xhr.responseText);
  } catch (e) {
    result = xhr.responseText as unknown as T;
  }

  return { response: xhr.response, result, error: null };
}

/**
 * Handles errors from the XMLHttpRequest.
 * @param xhr The XMLHttpRequest instance.
 */
function handleXHRError<E extends ApiClientErrorType>(xhr: XMLHttpRequest): ApiClientResponse<null, E> {
  let httpError: E | null = null;
  const localError = !xhr.statusText ? { name: 'UnknownError', message: 'Unknown request error' } : null;
  try {
    httpError = JSON.parse(xhr.responseText);
  } catch (e) {
    httpError = new Error(xhr.responseText) as E;
  }

  return {
    response: xhr.response,
    result: null,
    error: {
      http: httpError,
      status: xhr.status || HTTPStatus.Empty,
      local: localError,
    },
  };
}

/**
 * Sets up and sends an XMLHttpRequest.
 * @param url The URL for the request.
 * @param options Configuration options for the request.
 */
async function setupXHR<T, E extends ApiClientErrorType>(
  url: string | URL,
  options: ApiClientRequestOptions & ApiClientOptions,
): Promise<ApiClientResponse<T, null> | ApiClientResponse<null, E>> {
  const xhr = new XMLHttpRequest();
  xhr.open(options.method as string, url, true);
  setHeaders({ request: xhr, ...options });

  return new Promise((resolve) => {
    if (!options.body) {
      resolve({
        response: xhr.response,
        result: null,
        error: {
          http: null,
          status: HTTPStatus.Empty,
          local: { name: 'BadRequest', message: 'XHR API Client requires a body.' },
        },
      });

      return;
    }

    if (!isXMLHttpRequestBodyInit(options.body)) {
      resolve({
        response: xhr.response,
        result: null,
        error: {
          http: null,
          status: HTTPStatus.Empty,
          local: { name: 'BadRequest', message: 'XHR API Client supports only XMLHttpRequestBodyInit.' },
        },
      });

      return;
    }

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(handleResponse<T>(xhr));
        } else {
          resolve(handleXHRError<E>(xhr));
        }
      }
    };

    if (options.onProgress) {
      xhr.upload.onprogress = (event) => options.onProgress?.(event);
    }
    xhr.upload.onerror = () => resolve(handleXHRError<E>(xhr));
    xhr.send(options.body);
  });
}

/**
 * Sends a request using XMLHttpRequest.
 * @param url The URL for the request.
 * @param options Configuration options for the request.
 */
export async function xhrRequest<T, E extends ApiClientErrorType>(
  url: string | URL,
  options: ApiClientRequestOptions & ApiClientOptions,
): Promise<ApiClientResponse<T | null, E>> {
  try {
    return await setupXHR<T, E>(url, options);
  } catch (error) {
    const typedError = error instanceof Error ? error : new Error('An unknown error occurred');

    return {
      result: null,
      response: new Response(),
      error: {
        http: null,
        status: HTTPStatus.Empty,
        local: typedError,
      },
    };
  }
}
